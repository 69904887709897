<template>
  <div>
    <div class="columns is-marginless is-multiline">
      <div class="column is-12 is-4-desktop is-3-widescreen">
        <div class="content has-text-grey has-padding-x-50">
          <h2>Tasks</h2>
          <p>
            Getting help with your website is simple. Select from our predefined
            list of tasks or request a custom task. Our team of experts are on
            hand and ready to help round-the-clock. 😊
          </p>
        </div>
      </div>

      <div class="column is-12 is-8-desktop is-9-widescreen">
        <div class="columns is-multiline">
          <site-credits-total :site-id="siteId" @createTask="createTask" />
          <div class="column is-12">
            <card>
              <template slot="body">
                <tasks-table
                  v-bind="taskTableProps"
                  :admin-context="false"
                  @createTask="createTask"
                />
                <floating-button
                  v-if="(site.quota || {}).totalTasks"
                  label="Add new task"
                  class="is-hidden-mobile"
                  @click="createTask"
                />
              </template>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doc } from "@firebase/firestore";
export default {
  name: "SiteTasks",
  components: {
    "tasks-table": () => import("@shared/tasks/_tasksTable"),
    "site-credits-total": () => import("@shared/sites/_siteCreditsTotal")
  },
  data() {
    return {
      siteId: this.$route.params.siteId
    };
  },
  computed: {
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    isTouch() {
      return this.$store.getters["ui/isTouch"];
    },
    hasTaskCredits() {
      return this.$store.getters["sites/site/hasTaskCredits"](this.siteId);
    },
    isAgent() {
      return this.$store.getters["user/isAgent"]();
    },
    taskTableProps() {
      if (this.isAgent) {
        return {
          siteId: this.siteId,
          includeDeleted: true,
          adminContext: true
        };
      }
      return {
        siteId: this.siteId,
        participantId: this.userId
      };
    }
  },
  methods: {
    createTask(e) {
      const taskType = this.$_.get(e, "taskType");
      this.$store.dispatch("tasks/openAddTaskModal", {
        siteId: this.siteId,
        taskRef: taskType ? doc(this.$firestore, taskType) : null
      });
    },
    upgradeSubscription() {
      this.$modal.open({
        component: () => import("@shared/contracts/_upgradeModal"),
        parent: this,
        width: 960,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          fqdn: this.site.fqdn,
          siteId: this.siteId,
          userId: this.site.authorId,
          isSubscribed: this.site.isSubscribed
        }
      });
    }
  }
};
</script>
